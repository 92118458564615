import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import BannerImage from "assets/images/BannerImage.png";
import Animate from "components/Animate";
import ThemeWrapper from "components/ThemeWrapper";
import useDisplay from "contexts/Display";
import useUser from "contexts/User";
import React, { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styledFlexWrapper } from "styles";

const MastheadBannerSection: FC = () => {
  const navigate = useNavigate();
  const { displayMode } = useDisplay();
  const { userDetails } = useUser();
  const imageEl = useRef<HTMLImageElement>();
  const [lazyLoading, setLazyLoading] = useState<Boolean>(true);

  const handleImageLoad = () => {
    setLazyLoading(false);
  };

  return (
    <Box
      sx={{
        ...styledFlexWrapper,
        background:
          "linear-gradient(rgb(234, 230, 255), rgb(255, 255, 255)) no-repeat",
        // background: 'linear-gradient(180deg,transparent,hsla(0,0%,95%,.29) 47.39%,#fff 65%),linear-gradient(90deg,#f9ddf1 .07%,#e5e1ff 16.73%,#daedff 34.48%)',
        // backgroundImage: 'radial-gradient(145.05% 100% at 50% 0,rgb(243, 242, 255) 0,#f7f9fd 57.38%,#FFF 88.16%)',
        pt: 13,
        pb: 5,
        minHeight: "0",
      }}
    >
      <Box
        sx={{
          maxWidth: {
            xs: "100%",
            sm: "100%",
            md: "1300px",
            lg: "1300px",
          },
          margin: "auto",
        }}
      >
        <Animate type="fade-up" showOnScroll={false}>
          <Box
            sx={{
              display: "flex",
              overflow: "hidden",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "space-between",
              borderRadius: 1,
              pt: 5,
              pb: 15,
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  lg: "flex",
                },
                "@media screen and (max-width: 900px)": {
                  mt: 7,
                },
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "40%",
                  lg: "40%",
                },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ThemeWrapper>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 2,
                    fontSize: {
                      xs: 32,
                      sm: 32,
                      md: 34,
                      lg: 34,
                    },
                    "@media screen and (max-width: 900px)": {
                      mt: 0,
                    },
                    color: "#4B4B4B",
                    lineHeight: 1.3,
                  }}
                >
                  the free and fun way for devs to learn UI/UX design!
                  {/* <br /> */}
                  {/* <Typography
                    variant="h1"
                    sx={{
                      fontSize: {
                        xs: 32,
                        sm: 32,
                        md: 34,
                        lg: 34,
                      },
                      textFillColor: "transparent",
                      backgroundImage: "linear-gradient(90deg,#7928CA,#FF0080)",
                      backgroundClip: "text",
                      fontWeight: 700,
                      display: "inline",
                    }}
                  >
                    developer
                  </Typography> */}
                </Typography>
                {/* <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 400,
                    color: "#2B2C33",
                    fontSize: {
                      xs: 18,
                      sm: 18,
                      md: 20,
                      lg: 20,
                    },
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "70%",
                      lg: "70%",
                    },
                  }}
                >
                  By taking our interactive test, you'll learn how to apply best
                  practices when designing a UI.
                </Typography> */}
                <Button
                  type="button"
                  color="primary"
                  disableElevation
                  variant="contained"
                  onClick={() => {
                    navigate("/question/redesigning_ui/1");
                  }}
                  size="large"
                  sx={{
                    mt: 3,
                    fontWeight: 600,
                    textTransform: "none",
                    fontSize: 16,
                  }}
                >
                  Get Started
                </Button>
              </ThemeWrapper>
            </Box>

            {/* Banner Image */}
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "55%",
                  lg: "55%",
                },
                mt: {
                  xs: 8,
                  sm: 8,
                  md: 0,
                  lg: 0,
                },
              }}
            >
              {lazyLoading && (
                <Box
                  sx={{
                    backgroundColor: "transparent",
                    width: "900px",
                    height: "450px",
                    position: "relative",
                    zIndex: 20,
                  }}
                ></Box>
              )}
              <Box
                component="img"
                src={BannerImage}
                ref={imageEl}
                onLoad={handleImageLoad}
                sx={{
                  zIndex: 15,
                  position: "relative",
                  width: {
                    xs: "90%",
                    sm: "90%",
                    md: "90%",
                    lg: "90%",
                  },
                }}
              ></Box>
            </Box>
          </Box>
        </Animate>
      </Box>
    </Box>
  );
};

export default MastheadBannerSection;
