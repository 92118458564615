import { Box, Button, Link, Typography } from "@mui/material";
import AnswerImage from "assets/images/answer-image.png";
import StyledChip from "components/Globals/StyledChip";
import React, { useEffect, useState } from "react";
import { FaDiscord } from "react-icons/fa6";
import { Path } from "utils/apiTypes";
import { PATHS_DATA } from "../../statics/paths";
import MastheadBannerSection from "./MastheadBannerSection";

const Masthead: React.FC = () => {
  const [paths, setPaths] = useState(PATHS_DATA);
  const [currentPathId, setCurrentPathId] = useState<string>("");

  // set initial paths data
  useEffect(() => {
    localStorage.setItem("paths", JSON.stringify(paths) || "[]");
    paths?.every((path: Path, key: number) => {
      if (!path.totalResult) {
        setCurrentPathId(path.id);
        return false;
      }
      return true;
    });
  }, [paths]);

  return (
    <>
      <MastheadBannerSection />
      <Box
        sx={{
          backgroundColor: "#FFF",
          pt: {
            xs: 0,
            sm: 0,
            md: 10,
            lg: 10,
          },
          pb: {
            xs: 10,
            sm: 10,
            md: 20,
            lg: 20,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            maxWidth: {
              xs: "100%",
              sm: "100%",
              md: "1300px",
              lg: "1300px",
            },
            margin: "auto",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "60%",
                lg: "60%",
              },
            }}
          >
            <img src={AnswerImage} style={{ width: "100%" }} />
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "40%",
                lg: "40%",
              },
              pl: {
                xs: 3,
                sm: 3,
                md: 8,
                lg: 8,
              },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                width: "100%",
                fontSize: {
                  xs: 28,
                  sm: 28,
                  md: 38,
                  lg: 38,
                },
              }}
            >
              Learn by understanding <br />
              the reason behind <br />
              every correct answer.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: 2,
                fontWeight: 400,
                width: {
                  xs: "90%",
                  sm: "90%",
                  md: "100%",
                  lg: "100%",
                },
                color: "text.secondary",
                fontSize: {
                  xs: 18,
                  sm: 18,
                  md: 20,
                  lg: 20,
                },
              }}
            >
              Even though some correct answers are subjective, you must still
              understand the reasoning behind the implementation of the design.
              Otherwise, you won't be able to solve some real world design
              problems.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          py: {
            xs: 10,
            sm: 10,
            md: 25,
            lg: 25,
          },
          backgroundColor: "#000",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            maxWidth: {
              xs: "100%",
              sm: "100%",
              md: "1300px",
              lg: "1300px",
            },
            margin: "auto",
          }}
        >
          <Box
            sx={{
              pl: {
                xs: 3,
                sm: 3,
                md: 0,
                lg: 0,
              },
            }}
          >
            <StyledChip label="Ebook" />
            <Typography
              variant="h1"
              sx={{
                width: "100%",
                mt: 2,
                color: "#FFF",
                fontSize: {
                  xs: 28,
                  sm: 28,
                  md: 58,
                  lg: 58,
                },
              }}
            >
              A compendium of solutions <br /> for real-life UX problems
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: 3,
                fontWeight: 400,
                width: {
                  xs: "90%",
                  sm: "90%",
                  md: "70%",
                  lg: "70%",
                },
                color: "#ebebeb",
                fontSize: {
                  xs: 18,
                  sm: 18,
                  md: 22,
                  lg: 22,
                },
              }}
            >
              An exclusive eBook from OneGuru that focuses on everyday user
              experiences on real-life software applications. You can acquire
              this book upon availing our pro membership.
            </Typography>
          </Box>
          <Box
            sx={{
              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
              },
              width: {
                xs: "280px",
                sm: "280px",
                md: "550px",
                lg: "550px",
              },
              transform: "rotate(10deg)",
            }}
          >
            <img
              src={require("../../assets/images/black-ux-book.png")}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ textAlign: "center", py: "100px", width: "100%" }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: 32,
              sm: 32,
              md: 46,
              lg: 46,
            },
            fontWeight: 700,
          }}
        >
          Community
        </Typography>
        <Typography
          sx={{
            mt: 3,
            fontWeight: 400,
            color: "#2B2C33",
            textAlign: "center",
            px: {
              md: 80,
              sm: "100%",
            },
            fontSize: {
              md: 18,
            },
          }}
        >
          OneGuru community provides guides, help, and other educational content
          to assist designers in enhancing their skills and mastering UI/UX.
        </Typography>
        <Link href="https://discord.gg/87dTyRdmJA" target="_blank">
          <Button
            color="primary"
            variant="outlined"
            sx={{ mr: 4, mt: 4 }}
            startIcon={<FaDiscord style={{ color: "#4c66ff" }} />}
          >
            <Typography variant="subtitle2">Join our Discord</Typography>
          </Button>
        </Link>
      </Box>
    </>
  );
};

export default Masthead;
