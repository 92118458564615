import { Avatar, Box, Button, Drawer, Link, Typography } from "@mui/material";
import { ReactComponent as MainLogo } from "assets/mainLogo.svg";
import SunIcon from "components/Icons/SunIcon";
import useDisplay from "contexts/Display";
import useUser from "contexts/User";
import React, { useEffect, useState } from "react";
import { FaDiscord } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import BurgerIcon from "../../Icons/BurgerIcon";
import UserDropdown from "../AuthNavigation/UserDropdown";
import MobileNavigation from "./MobileNavigation";
import styles from "./UnauthNavigation.module.scss";

interface Display {
  LIGHT: Array<string>;
  DARK: Array<string>;
  [key: string]: any;
}

const displayModeList: Display = {
  LIGHT: ["NavbarWrapper", "NavbarWrapper_Scroll"],
  DARK: ["NavbarWrapper_Dark", "NavbarWrapper_DarkScroll"],
};

const UnauthNavigation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { displayMode, updateDisplayMode } = useDisplay();
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [openMobileNav, setOpenMobileNav] = useState<boolean>(false);
  const { userDetails, updateAuditLog } = useUser();

  // @ts-ignore
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(true);
      if (window.scrollY == 0) {
        setIsScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, []);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpenMobileNav(!openMobileNav);
    };

  const setClassName = () => {
    return isScrolling
      ? displayModeList[displayMode][1]
      : displayModeList[displayMode][0];
  };

  const DisplayThemeButton = () => {
    return (
      <button
        className={
          displayMode === "LIGHT" ? styles.DarkModeBtn : styles.LightModeBtn
        }
        type="button"
        onClick={() =>
          updateDisplayMode(displayMode === "LIGHT" ? "DARK" : "LIGHT")
        }
      >
        {displayMode === "LIGHT" ? (
          // <MoonIcon />
          <></>
        ) : (
          <SunIcon />
        )}
      </button>
    );
  };

  return (
    <>
      {/* DESKTOP NAV */}
      <header className={styles[setClassName()]}>
        <div className={styles.NavbarContainer_Light}>
          <div className={styles.NavbarRow}>
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
              }}
            >
              <Button
                onClick={() => navigate("/")}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    pl: 0.8,
                    backgroundImage:
                      "radial-gradient(145.05% 100% at 50% 0,#1d2b41 0,#0b1627 57.38%,#142133 88.16%)",
                    boxShadow: "0 5px 12px rgba(0,0,0,.12)",
                    pr: 0.7,
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MainLogo />
                </Box>
                <Box sx={{ ml: 1.5 }}>
                  <Typography variant="h5" fontSize={16}>
                    OneGuru
                  </Typography>
                </Box>
              </Button>
              <Box sx={{ mt: 0.4, ml: 7 }} className="HideMobile"></Box>
            </Box>

            {/* DESKTOP NAV ITEMS */}
            <div className={styles.NavItems_Column}>
              <ul className={styles.NavItems}>
                <li></li>
                <li className="HideMobile FlexNowrap">
                  {localStorage.getItem("accessToken") ? (
                    <>
                      <Box sx={{ mt: 0.4, mr: 3 }}>
                        <Button
                          onClick={() => navigate("/paths")}
                          sx={{
                            borderBottom:
                              location.pathname === "/paths"
                                ? "2px solid black"
                                : "0px",
                            borderRadius: "0px",
                          }}
                        >
                          <Typography variant="body2">Paths</Typography>
                        </Button>
                      </Box>
                      <Link
                        href="https://discord.gg/87dTyRdmJA"
                        target="_blank"
                      >
                        <Button
                          color="primary"
                          sx={{ mr: 4 }}
                          onClick={() => navigate("/login")}
                          startIcon={
                            <Box
                              style={{
                                backgroundColor: "#4c66ff",
                                borderRadius: 6,
                                padding: 4,
                                height: 30,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <FaDiscord style={{ color: "white" }} />
                            </Box>
                          }
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color:
                                displayMode === "DARK" ? "#FFF" : "#1A1A1A",
                            }}
                          >
                            Join our Discord
                          </Typography>
                        </Button>
                      </Link>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => navigate("/question/redesigning_ui/1")}
                        disableElevation
                      >
                        Start Now
                      </Button>
                      <Box sx={{ mx: 2 }}>
                        <UserDropdown />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Link
                        href="https://discord.gg/87dTyRdmJA"
                        target="_blank"
                      >
                        <Button
                          color="primary"
                          sx={{ mr: 4 }}
                          onClick={() => navigate("/login")}
                          startIcon={
                            <Box
                              style={{
                                backgroundColor: "#4c66ff",
                                borderRadius: 6,
                                padding: 4,
                                height: 30,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <FaDiscord style={{ color: "white" }} />
                            </Box>
                          }
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color:
                                displayMode === "DARK" ? "#FFF" : "#1A1A1A",
                            }}
                          >
                            Join our Discord
                          </Typography>
                        </Button>
                      </Link>
                      <Button
                        color="primary"
                        sx={{ mr: 1.5 }}
                        onClick={() => navigate("/login")}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: displayMode === "DARK" ? "#FFF" : "#000",
                          }}
                        >
                          Sign In
                        </Typography>
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => navigate("/question/redesigning_ui/1")}
                        disableElevation
                      >
                        Start Now
                      </Button>
                    </>
                  )}
                </li>

                {/* Mobile burger icon */}
                <li className={styles.BurgerNav}>
                  <Box sx={{ display: "flex" }}>
                    {accessToken && (
                      <Avatar
                        //@ts-ignore
                        src={localStorage.getItem("profileImage")}
                        style={{
                          borderRadius: "100%",
                          height: 35,
                          width: 35,
                        }}
                      />
                    )}
                    <Button
                      color="primary"
                      onClick={toggleDrawer(true)}
                      sx={{
                        mb: 1,
                        minWidth: 0,
                        ml: 1,
                      }}
                    >
                      <BurgerIcon />
                    </Button>
                  </Box>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      {/* MOBILE NAV */}
      <Drawer anchor="left" open={openMobileNav} onClose={toggleDrawer(false)}>
        <MobileNavigation
          onClose={() => {
            setOpenMobileNav(false);
          }}
        />
      </Drawer>
    </>
  );
};

export default UnauthNavigation;
